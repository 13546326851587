(function(window, document, undefined) {
	'use strict';

	const links = document.querySelectorAll('[data-copy-href]');

	[].forEach.call(links, function(link) {
		var target = document.querySelector(link.getAttribute('data-copy-href'));

		link.href = target.href;
	});

})(window, document);
