var Menu = (function() {

	/**
	* Extend Object helper function.
	*/
	function extend(a, b) {
		for(var key in b) {
			if(b.hasOwnProperty(key)) {
				a[key] = b[key];
			}
		}
		return a;
	}

	/**
	* Each helper function.
	*/
	function each(collection, callback) {
		for (var i = 0; i < collection.length; i++) {
			var item = collection[i];
			callback(item);
		}
	}

	/**
	* Menu Constructor.
	*/
	function Menu(options) {
		this.options = extend({}, this.options);
		extend(this.options, options);
		this._init();
	}

	/**
	* Menu Options.
	*/
	Menu.prototype.options = {
		wrapper: '#site-header',          // The content wrapper
		type: 'slide-left',             // The menu type
		menuOpenerClass: '.menu-button--open',   // The menu opener class names (i.e. the buttons)
		maskId: '#mask'               // The ID of the mask
	};

	/**
	* Initialise Menu.
	*/
	Menu.prototype._init = function() {
		this.body = document.body;
		this.wrapper = document.querySelector(this.options.wrapper);
		this.mask = document.querySelector(this.options.maskId);
		this.menu = document.querySelector('#mega-menu--' + this.options.type);
		this.menuItemsWithSubmenu = document.getElementsByClassName('has--submenu');
		this.topMenuItems = document.getElementsByClassName('has--top-submenu');
		this.getQuoteItems = document.getElementsByClassName('tile--get-a-quote');
		this.getQuickQuoteContainer = document.getElementById('get-a-quick-quote');
		this.getQuoteContainerItems = document.getElementsByClassName('get-a-quote-container');
		this.closeBtn = document.querySelector('.menu-button--close');
		this.getQuotecloseBtn = document.querySelector('.get-quote-button--close');
		this.menuOpeners = document.querySelectorAll(this.options.menuOpenerClass);
		this._initEvents();
	};

	/**
	* Initialise Menu Events.
	*/
	Menu.prototype._initEvents = function() {
		// Event for clicks on the close button inside the menu.
		this.closeBtn.addEventListener('click', function(e) {
			e.preventDefault();
			this.close();
		}.bind(this));


		if(this.getQuotecloseBtn) {
			this.getQuotecloseBtn.addEventListener('click', function(e) {
				e.preventDefault();
				this.close();
			}.bind(this));
		}

		// Open/Close Submenu
		each(this.menuItemsWithSubmenu, function(item){
			item.addEventListener('click', function(e) {
				if (e.target.classList.contains('mega-menu__link')) {
					e.preventDefault();
				}

				if(item.classList.contains('is-active-menu')) {
					item.classList.remove('is-active-menu')
				} else if(document.querySelector('.is-active-menu')) {
					document.querySelector('.is-active-menu').classList.remove('is-active-menu');
					item.classList.add('is-active-menu')
				} else {
					item.classList.add('is-active-menu');
				}
				item.scrollIntoView();
			}.bind(this));

			item.addEventListener('mouseover', function(e) {
				document.querySelector('.mask').classList.add('is-active');
			}.bind(this));

			item.addEventListener('mouseout', function(e) {
				document.querySelector('.mask').classList.remove('is-active');
			}.bind(this));
		});

		// Open/Close Topmenu
		each(this.topMenuItems, function(item){
			item.addEventListener('click', function(e) {
				e.stopPropagation();
				item.classList.add('is-active-menu');
				document.body.classList.add('has-active-top-menu');
				document.querySelector('.mask').classList.add('is-active');
				item.scrollIntoView();
			}.bind(this));
		});

		// Open/Close Get a quote
		if(this.getQuoteItems.length > 0) {
			each(this.getQuoteItems, function(item){
				item.querySelector('.tile__content__get-a-quote').addEventListener('click', function(e) {
					e.preventDefault();
					item.classList.add('tile--get-a-quote--active');
					document.body.classList.add('has-active-get-quote');
					document.querySelector('.mask').classList.add('is-active');
					document.getElementById(e.target.getAttribute('data-lightbox-id')).classList.add('get-a-quote-container--is-active');
					item.scrollIntoView();
				}.bind(this));
			});
		}

		var getAQuickQuote = document.getElementById('get-a-quick-quote');
		if (getAQuickQuote) {
			getAQuickQuote.addEventListener('click', function(e) {
				document.body.classList.add('has-active-get-quote');
				document.querySelector('.mask').classList.add('is-active');
				document.getElementById('professional-indemnity-lightbox').classList.add('get-a-quote-container--is-active');
				getAQuickQuote.scrollIntoView();
			});
		}
	};

	/**
	* Open Menu.
	*/
	Menu.prototype.open = function() {
		this.body.classList.add('has-active-menu');
		this.wrapper.classList.add('has-' + this.options.type);
		this.menu.classList.add('is-active');
		this.mask.classList.add('is-active');
		this.disableMenuOpeners();
	};

	/**
	* Close Menu.
	*/
	Menu.prototype.close = function() {
		this.body.classList.remove('has-active-menu');
		this.body.classList.remove('has-active-top-menu');
		this.body.classList.remove('has-active-get-quote');

		each(this.topMenuItems, function(item) {
			item.classList.remove('is-active-menu');
		});

		if(this.getQuoteItems.length > 0) {
			each(this.getQuoteItems, function(item) {
				item.classList.remove('tile--get-a-quote--active');
			});
		}

		if(this.getQuoteContainerItems.length > 0) {
			each(this.getQuoteContainerItems, function(item) {
				item.classList.remove('get-a-quote-container--is-active');
			});
		}

		this.wrapper.classList.remove('has-' + this.options.type);
		this.menu.classList.remove('is-active');
		this.mask.classList.remove('is-active');
		this.enableMenuOpeners();
	};

	/**
	* Disable Menu Openers.
	*/
	Menu.prototype.disableMenuOpeners = function() {
		each(this.menuOpeners, function(item) {
			item.disabled = true;
		});
	};

	/**
	* Enable Menu Openers.
	*/
	Menu.prototype.enableMenuOpeners = function() {
		each(this.menuOpeners, function(item) {
			item.disabled = false;
		});
	};

	/**
	* Slide left instantiation and action.
	*/
	var slideLeft = new Menu({
		wrapper: '#site-header',
		type: 'slide-left',
		menuOpenerClass: '.menu-button--open',
		maskId: '#mask'
	});

	var slideLeftBtn = document.querySelector('#menu-button--slide-left');

	slideLeftBtn.addEventListener('click', function(e) {
		e.preventDefault;
		slideLeft.open();
	});

})();


