(function() {

	'use strict';

	/**
	 * tabs
	 *
	 * @description The Tabs component.
	 * @param {Object} options The options hash
	 */
	var tabs = function(options) {

		var el = document.querySelector(options.el);
		var tabNavigationLinks = el.querySelectorAll(options.tabNavigationLinks);
		var tabContentContainers = el.querySelectorAll(options.tabContentContainers);
		var activeIndex = 0;
		var initCalled = false;

		/**
		 * init
		 *
		 * @description Initializes the component by removing the no-js class from
		 *   the component, and attaching event listeners to each of the nav items.
		 *   Returns nothing.
		 */
		var init = function() {
			if (!initCalled) {
				initCalled = true;
				el.classList.remove('no-js');

				for (var i = 0; i < tabNavigationLinks.length; i++) {
					var link = tabNavigationLinks[i];
					handleClick(link, i);
				}
			}
		};

		/**
		 * handleClick
		 *
		 * @description Handles click event listeners on each of the links in the
		 *   tab navigation. Returns nothing.
		 * @param {HTMLElement} link The link to listen for events on
		 * @param {Number} index The index of that link
		 */
		var handleClick = function(link, index) {
			link.addEventListener('click', function(e) {
				e.preventDefault();
				goToTab(index);
			});
		};

		/**
		 * goToTab
		 *
		 * @description Goes to a specific tab based on index. Returns nothing.
		 * @param {Number} index The index of the tab to go to
		 */
		var goToTab = function(index) {
			if (index !== activeIndex && index >= 0 && index <= tabNavigationLinks.length) {
				tabNavigationLinks[activeIndex].classList.remove('tabs__nav__link--is-active');
				tabNavigationLinks[index].classList.add('tabs__nav__link--is-active');
				tabContentContainers[activeIndex].classList.remove('tabs__content--is-active');
				tabContentContainers[index].classList.add('tabs__content--is-active');
				activeIndex = index;
			}
		};

		/**
		 * Returns init and goToTab
		 */
		return {
			init: init,
			goToTab: goToTab
		};

	};

	if(document.querySelectorAll('.tabs__nav__link').length > 0) {
		var myTabs = tabs({
			el: '#tabs',
			tabNavigationLinks: '.tabs__nav__link',
			tabContentContainers: '.tabs__content'
		});

		myTabs.init();
	}

})();
