/**
 * Toolkit JavaScript
 * This file should only import other script files.
 */
'use strict';

import "./svgxuse";
import "./copy-href";
import "./prevent-click";
import "./menu";
import "./tabs";
