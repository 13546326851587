(function(window, document, undefined) {
	'use strict';

	const links = document.getElementsByClassName('prevent-click');

	[].forEach.call(links, function(link) {
		link.addEventListener('click', function(e) {
			e.preventDefault();
		});
	});

})(window, document);
